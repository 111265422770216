import { Box, Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DigitalSidebar from "../../components/digitalSidebar";
import Header from "../../components/header";
import PDFViewer from "../../components/PDFViewr";
import SideBar from "../../components/sideBar";
import * as bookServices from "../../services/book.services";
import "./digitalbook.css";

const DigitalBook = () => {
  const bookId = useParams().bookId;
  const [book, setBook] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State to manage sidebar visibility
  const [isOpen, setIsOpen] = useState(true); // Default to open
  useEffect(() => {
    setIsLoading(true);
    bookServices
      .getBookById(bookId)
      .then((response) => {
        setBook(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [bookId]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    setIsOpen(!isSidebarOpen);
  };

  return (
    <>
      <Header />

      <Box sx={{ p: 2 }}>
        <Grid container spacing={2} sx={{ position: "relative" }}>
          <SideBar />

          <Grid
            className={`sidebar-grid ${!isSidebarOpen ? "minimized" : ""}`}
            item
            xs={12}
            sm="auto"
            sx={{
              overflow: "hidden",
              position: "relative",
            }}
          >
            {" "}
            <DigitalSidebar
              book={book}
              setPageNumber={setPageNumber}
              isOpen={isOpen}
              onToggle={toggleSidebar}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm
            className="main-content-grid"
            sx={{
              flexGrow: 1,
              overflow: "hidden",
              marginLeft: 0,
            }}
          >
            {isLoading ? (
              <Paper sx={{ p: 2 }}>Loading...</Paper>
            ) : (
              <PDFViewer
                pdfURL={book.digitalEdition}
                propPageNumber={pageNumber}
                mode="digital"
                bookId={bookId}
                sample={false}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default DigitalBook;
