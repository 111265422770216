import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HeadsetIcon from "@mui/icons-material/Headset";
import OfflinePinIcon from "@mui/icons-material/OfflinePin";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  Snackbar,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import { Link } from "react-router-dom";
import "./digitalSidebar.css";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";

const DigitalSidebar = ({ book, setPageNumber, onToggle }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [cachedFiles, setCachedFiles] = useState({});
  const [snackbarOpenMedia, setSnackbarOpenMedia] = useState(false);
  const [expandedAccordions, setExpandedAccordions] = useState({});
  const [isOpen, setIsOpen] = useState(true);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const handleAudioPlay = (voiceFileName) => {
    const audioElements = document.getElementsByTagName("audio");
    for (let i = 0; i < audioElements.length; i++) {
      const audioElement = audioElements[i];
      if (voiceFileName === audioElement.src) {
        audioElement.play();
      } else {
        audioElement.pause();
      }
    }
  };

  const toggleAudioControls = (page, pageNumber) => {
    const audioControls = document.querySelector(
      `.audio-play-buttons_${page}_${pageNumber}`
    );
    audioControls.style.display =
      audioControls.style.display === "none" ? "block" : "none";
  };

  function audioPreLoad(file) {
    const audio = new Audio(file);
    audio.preload = "auto";
    audio.load();
  }

  // Check if the media is already cached when the component mounts
  useEffect(() => {
    if (book?.pages) {
      book.pages.forEach((page) => {
        page.voiceFiles?.forEach((voiceFile) => {
          checkIfMediaIsCached(voiceFile.url);
        });
        page.videoFiles?.forEach((videoFile) => {
          checkIfMediaIsCached(videoFile.url, "video");
        });
      });
    }
  }, [book]);

  // Check if the media file is cached and update cached status accordingly
  const checkIfMediaIsCached = async (fileUrl, fileType) => {
    const cacheName = "appV1";

    try {
      const cache = await caches.open(cacheName);
      const cachedResponse = await cache.match(fileUrl);

      setCachedFiles((prev) => ({
        ...prev,
        [fileUrl]: !!cachedResponse,
      }));
    } catch (error) {
      console.error(`Error checking ${fileType} cache:`, error);
    }
  };

  // Cache the media file
  const handleCacheMedia = async (fileUrl) => {
    const cache = await caches.open("appV1");
    await cache.add(fileUrl); // Cache the media URL
    checkIfMediaIsCached(fileUrl); // Recheck cache status
    setSnackbarOpenMedia(true);
  };

  // Handle snackbar close
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpenMedia(false);
  };
  // handles  accordion expansion
  const handleAccordionChange = (pageNumber) => (event, isExpanded) => {
    setExpandedAccordions((prev) => ({
      ...prev,
      [pageNumber]: isExpanded,
    }));
  };
  return (
    <div className={`content-wrapper ${!isOpen ? "closed" : "open"}`}>
      <IconButton
        className="side-toggle-button"
        sx={{ color: "White", backgroundColor: "#8b6f24" }}
        onClick={handleToggle}
        size="small"
      >
        {isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </IconButton>
      <Box className={`digital-sidebar-container ${isOpen ? "" : "closed"}`}>
        <>
          {/* Snackbar Notification */}
          <Snackbar
            open={snackbarOpenMedia}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }} // Top-right position
          >
            <Alert
              onClose={handleSnackbarClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              Media cached successfully!
            </Alert>
          </Snackbar>
          {/* Book Thumbnail and Title */}
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Link to={`/bookDetails/${book?._id}`}>
              <Avatar
                variant="square"
                src={book?.thumbnail?.replace(
                  "https://redsea-books-assets.s3.eu-central-1.amazonaws.com",
                  "https://dng2cduv9v2yj.cloudfront.net"
                )}
                alt="Book Thumbnail"
                sx={{ width: 60, height: 80, mr: 2 }}
              />
            </Link>
            <Typography variant="h6" sx={{ lineHeight: 1.2 }}>
              <Link
                to={`/bookDetails/${book?._id}`}
                className="book-media-link"
              >
                {" "}
                {book?.title}{" "}
              </Link>
            </Typography>
          </Box>

          {/* Tabs for Pages and Media */}
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            sx={{ backgroundColor: "white", color: "black", mb: 2 }}
            textColor="inherit"
            indicatorColor="secondary"
          >
            <Tab label="Pages & Media" />
            <Tab label="Media" />
          </Tabs>

          {/* Divider */}
          <Divider sx={{ backgroundColor: "#8d711f", mb: 2 }} />
          {/* Conditional Rendering Based on Selected Tab */}
          {selectedTab === 0 && (
            <List>
              {[...Array(book?.digitalEditionPageNumber)].map((_, index) => {
                const page = book?.pages?.find((p) => p.number === index + 1);
                const pageNumber = index + 1;

                return (
                  <Accordion
                    key={index}
                    //handles expanded accordions
                    expanded={expandedAccordions[pageNumber] || false}
                    //handles newly expanded accordions
                    onChange={handleAccordionChange(pageNumber)}
                    sx={{
                      backgroundColor: "white",
                      color: "black",
                      boxShadow: "none",
                      mb: 1,
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        page?.voiceFiles || page?.videoFiles ? (
                          <ExpandMoreIcon sx={{ color: "black" }} />
                        ) : null
                      }
                      onClick={() => setPageNumber(pageNumber)} // Ensure users can click and navigate to the PDF page
                      aria-controls={`panel${pageNumber}a-content`}
                      id={`panel${pageNumber}a-header`}
                    >
                      <Typography>Page {pageNumber}</Typography>{" "}
                      {/* Display page number */}
                    </AccordionSummary>

                    {page ? (
                      <>
                        {/* Voice files rendering -Expaneded only -*/}
                        {expandedAccordions[pageNumber] && page.voiceFiles && (
                          <AccordionDetails style={{ padding: "10px 0px" }}>
                            {page.voiceFiles.map((voiceFile, voiceIndex) => {
                              // Base64 encode the voice file URL
                              const encodedVoiceFileUrl = btoa(
                                voiceFile.url.replace(
                                  "https://redsea-books-assets.s3.eu-central-1.amazonaws.com",
                                  "https://dng2cduv9v2yj.cloudfront.net"
                                )
                              );
                              return (
                                <ListItem
                                  key={voiceIndex}
                                  style={{ display: "block" }}
                                  onClick={() => {
                                    toggleAudioControls(
                                      voiceIndex,
                                      page.number
                                    );
                                  }}
                                  button
                                >
                                  {audioPreLoad(atob(encodedVoiceFileUrl))}
                                  <div className="audio-file-title">
                                    <HeadsetIcon />
                                    <p style={{ cursor: "pointer" }}>
                                      {voiceFile.name
                                        ? voiceFile.name
                                        : decodeURIComponent(
                                            voiceFile.url
                                              ?.split("/")
                                              ?.pop()
                                              ?.replace(".mp3", "")
                                              ?.replace(/_/g, " ")
                                              ?.replace(/\+/g, " ")
                                          )}
                                    </p>
                                  </div>
                                  <div
                                    style={{ display: "none" }}
                                    className={`audio-play-buttons_${voiceIndex}_${page.number}`}
                                  >
                                    <AudioPlayer
                                      src={atob(encodedVoiceFileUrl)}
                                      className={`audio_${page.number}`}
                                      onPlay={() => {
                                        handleAudioPlay(
                                          atob(encodedVoiceFileUrl)
                                        );
                                      }}
                                      progressJumpStep={5000}
                                    />
                                  </div>
                                  <Tooltip
                                    title={
                                      cachedFiles[voiceFile.url]
                                        ? "Audio is cached and available offline"
                                        : "Cache this audio file"
                                    }
                                  >
                                    {cachedFiles[voiceFile.url] ? (
                                      <OfflinePinIcon />
                                    ) : (
                                      <CloudDownloadIcon
                                        onClick={() =>
                                          handleCacheMedia(
                                            atob(encodedVoiceFileUrl)
                                          )
                                        }
                                      />
                                    )}
                                  </Tooltip>
                                </ListItem>
                              );
                            })}
                          </AccordionDetails>
                        )}

                        {/* Video files rendering -Expaneded only-*/}
                        {expandedAccordions[pageNumber] && page.videoFiles && (
                          <AccordionDetails style={{ padding: "10px 0px" }}>
                            <ListItem style={{ display: "block" }}>
                              {page.videoFiles.map((videoFile, videoIndex) => {
                                // Base64 encode the video file URL
                                const encodedVideoFileUrl = btoa(
                                  videoFile.url.replace(
                                    "https://redsea-books-assets.s3.eu-central-1.amazonaws.com",
                                    "https://dng2cduv9v2yj.cloudfront.net"
                                  )
                                );

                                return (
                                  <div key={videoIndex}>
                                    <video
                                      controls
                                      className="video-file-digitalsidebar"
                                      src={atob(encodedVideoFileUrl)}
                                    ></video>
                                    <br />
                                    <Tooltip
                                      title={
                                        cachedFiles[atob(encodedVideoFileUrl)]
                                          ? "Video is cached and available offline"
                                          : "Cache this video"
                                      }
                                    >
                                      {cachedFiles[
                                        atob(encodedVideoFileUrl)
                                      ] ? (
                                        <OfflinePinIcon />
                                      ) : (
                                        <CloudDownloadIcon
                                          onClick={() =>
                                            handleCacheMedia(
                                              atob(encodedVideoFileUrl)
                                            )
                                          }
                                        />
                                      )}
                                    </Tooltip>
                                  </div>
                                );
                              })}
                            </ListItem>
                          </AccordionDetails>
                        )}
                      </>
                    ) : (
                      <AccordionDetails>
                        <ListItem
                          button
                          onClick={() => setPageNumber(pageNumber)} // Allow clicking on the item to navigate to the page in the PDF
                          style={{ cursor: "pointer" }}
                        >
                          <Typography>
                            No content available for Page {pageNumber}
                          </Typography>
                        </ListItem>
                      </AccordionDetails>
                    )}
                  </Accordion>
                );
              })}
            </List>
          )}
          {selectedTab === 1 && (
            <List>
              {book?.pages
                .filter(
                  (page) =>
                    page?.voiceFiles?.length > 0 || page?.videoFiles?.length > 0 // Only pages with media
                )
                .map((page, index) => {
                  return (
                    <Accordion
                      key={index}
                      // Handles expanded accordions
                      expanded={expandedAccordions[page.number] || false}
                      // Handles newly expanded accordions
                      onChange={handleAccordionChange(page.number)}
                      sx={{
                        backgroundColor: "white",
                        color: "black",
                        boxShadow: "none",
                        mb: 1,
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          page?.voiceFiles || page?.videoFiles ? (
                            <ExpandMoreIcon sx={{ color: "black" }} />
                          ) : null
                        }
                        onClick={() => setPageNumber(page.number)} // Ensure users can click and navigate to the PDF page
                        aria-controls={`panel${page.number}a-content`}
                        id={`panel${page.number}a-header`}
                      >
                        <Typography>Page {page.number}</Typography>{" "}
                        {/* Display page number */}
                      </AccordionSummary>

                      {page ? (
                        <>
                          {/* Voice files rendering - Expanded only */}
                          {expandedAccordions[page.number] &&
                            page.voiceFiles && (
                              <AccordionDetails style={{ padding: "10px 0px" }}>
                                {page.voiceFiles.map(
                                  (voiceFile, voiceIndex) => {
                                    // Base64 encode the voice file URL
                                    const encodedVoiceFileUrl = btoa(
                                      voiceFile.url.replace(
                                        "https://redsea-books-assets.s3.eu-central-1.amazonaws.com",
                                        "https://dng2cduv9v2yj.cloudfront.net"
                                      )
                                    );
                                    return (
                                      <ListItem
                                        key={voiceIndex}
                                        style={{ display: "block" }}
                                        onClick={() => {
                                          toggleAudioControls(
                                            voiceIndex,
                                            page.number
                                          );
                                        }}
                                        button
                                      >
                                        {audioPreLoad(
                                          atob(encodedVoiceFileUrl)
                                        )}
                                        <div className="audio-file-title">
                                          <HeadsetIcon />
                                          <p style={{ cursor: "pointer" }}>
                                            {voiceFile.name
                                              ? voiceFile.name
                                              : decodeURIComponent(
                                                  voiceFile.url
                                                    ?.split("/")
                                                    ?.pop()
                                                    ?.replace(".mp3", "")
                                                    ?.replace(/_/g, " ")
                                                    ?.replace(/\+/g, " ")
                                                )}
                                          </p>
                                        </div>
                                        <div
                                          style={{ display: "none" }}
                                          className={`audio-play-buttons_${voiceIndex}_${page.number}`}
                                        >
                                          <AudioPlayer
                                            src={atob(encodedVoiceFileUrl)}
                                            className={`audio_${page.number}`}
                                            onPlay={() => {
                                              handleAudioPlay(
                                                atob(encodedVoiceFileUrl)
                                              );
                                            }}
                                            progressJumpStep={5000}
                                          />
                                        </div>
                                        <Tooltip
                                          title={
                                            cachedFiles[voiceFile.url]
                                              ? "Audio is cached and available offline"
                                              : "Cache this audio file"
                                          }
                                        >
                                          {cachedFiles[voiceFile.url] ? (
                                            <OfflinePinIcon />
                                          ) : (
                                            <CloudDownloadIcon
                                              onClick={() =>
                                                handleCacheMedia(
                                                  atob(encodedVoiceFileUrl)
                                                )
                                              }
                                            />
                                          )}
                                        </Tooltip>
                                      </ListItem>
                                    );
                                  }
                                )}
                              </AccordionDetails>
                            )}

                          {/* Video files rendering - Expanded only */}
                          {expandedAccordions[page.number] &&
                            page.videoFiles && (
                              <AccordionDetails style={{ padding: "10px 0px" }}>
                                <ListItem style={{ display: "block" }}>
                                  {page.videoFiles.map(
                                    (videoFile, videoIndex) => {
                                      // Base64 encode the video file URL
                                      const encodedVideoFileUrl = btoa(
                                        videoFile.url.replace(
                                          "https://redsea-books-assets.s3.eu-central-1.amazonaws.com",
                                          "https://dng2cduv9v2yj.cloudfront.net"
                                        )
                                      );

                                      return (
                                        <div key={videoIndex}>
                                          <video
                                            controls
                                            className="video-file-digitalsidebar"
                                            src={atob(encodedVideoFileUrl)}
                                          ></video>
                                          <br />
                                          <Tooltip
                                            title={
                                              cachedFiles[
                                                atob(encodedVideoFileUrl)
                                              ]
                                                ? "Video is cached and available offline"
                                                : "Cache this video"
                                            }
                                          >
                                            {cachedFiles[
                                              atob(encodedVideoFileUrl)
                                            ] ? (
                                              <OfflinePinIcon />
                                            ) : (
                                              <CloudDownloadIcon
                                                onClick={() =>
                                                  handleCacheMedia(
                                                    atob(encodedVideoFileUrl)
                                                  )
                                                }
                                              />
                                            )}
                                          </Tooltip>
                                        </div>
                                      );
                                    }
                                  )}
                                </ListItem>
                              </AccordionDetails>
                            )}
                        </>
                      ) : (
                        <AccordionDetails>
                          <ListItem
                            button
                            onClick={() => setPageNumber(page.number)} // Allow clicking on the item to navigate to the page in the PDF
                            style={{ cursor: "pointer" }}
                          >
                            <Typography>
                              No content available for Page {page.number}
                            </Typography>
                          </ListItem>
                        </AccordionDetails>
                      )}
                    </Accordion>
                  );
                })}
            </List>
          )}
        </>
      </Box>
    </div>
  );
};

export default DigitalSidebar;
